/* our custom styles */

/* TinyMCE styles */

.tiny-left {
    text-align:left;
}

.tiny-center {
    text-align:center;
}
.tiny-right {
    text-align: right;
}
.tiny-full {
    text-align: justify;
}

/* TinyMCE templates */

// image-grid-2x2:
.image-grid-2x2 {
    padding: 0;
    margin: 0 0 @plone-grid-gutter-width 0;
    list-style: none;
    .make-row();

    > li {
        .make-xs-column(12);
        .make-sm-column(6);
        margin-top: @plone-grid-gutter-width;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

/* layout */

article#content {
    max-width: 901px;
}

#portal-footer .row + .site-actions {
    border-top: 0;
    text-align: left;
    ul {
        text-transform: none;
        font-size: 80%;
        padding-left: 0;
        margin-left: calc(@plone-grid-gutter-width / 2);
        margin-top: 1em;
        li {
            padding: 0;
            a {
                font-weight: @plone-font-weight-regular;
            }
        }
        li#siteaction-address, li#siteaction-copyright {
            margin-left: 0;
            margin-top: 10px;
            color: @oag-black;
            display: block;
        }
    }
}

/* basic styles */

.dont-wrap {
    white-space: nowrap;
}

.description {
    font-size: @plone-font-size-h4;
    font-weight: @plone-font-weight-regular;
    color: @plone-gray;
    font-style: italic;
}

.discreet {
    font-size: @plone-font-size-small;
}

.pullquote {
    font-family: @plone-font-family-serif;
//    font-size: @plone-font-size-small;
    font-style: italic;
//    background:  url(/++theme++auditnz2022/static/img/pull-quote.gif) no-repeat top left;
    padding: 0.64em 1em 1em 0;
    &:before {
        .fa-icon;
        .fas;
        content: @fa-var-quote-left;
        padding-right: 1em;
        font-size: @plone-font-size-large;
        color: @oag-teal;
    }
}

.callout, p.callout {
    border-left: 1em solid @oag-teal;
}

.updated {
    font-size: @plone-font-size-h6;
}

.cite, cite {
    font-style: italic;
}

pre {
    white-space: pre-wrap;
    word-break: normal;
}

.relatedItems {
    background: @oag-gray-lightest;
    padding: 1em;
    border-left: 1em solid @oag-teal;
    clear: both;
    header {
        font-weight: @plone-font-weight-bold;
        padding-bottom: 0.25em;
    }
    ul li {
        font-size: @plone-font-size-small;
    }
    a { color: @plone-link-color-on-grey;}
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25% !important; /* 16:9 */
	padding-top: 25px !important;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* typography */

h1, h2, h3, h4, h5, h6 {
    margin-top: @plone-line-height-computed;
}

// set font family for page structures

#mainnavigation {
    font-family: @plone-font-family-alt;
}

#portal-breadcrumbs {
    font-family: @plone-font-family-alt;
}

.portlet {
    font-family: @plone-font-family-alt;
}

.autotoc-nav {
    font-family: @plone-font-family-alt;
}

#portal-footer-wrapper {
    font-family: @plone-font-family-alt;
}

// base fontsize of 16px makes the navigation overflow to the right
// causing a horizontal scroll bar. We redefine effected variables
// and settings to values they would have with base font size 14px.

@plone-sitenav-margin-bottom: 20px;
@plone-sitenav-padding-vertical: 15px;

.plone-nav >li {
    >a {
        text-transform: lowercase;
        font-size: 17px;
    }
}

@media (min-width: @plone-grid-float-breakpoint) {
    .plone-nav .has_subtree .has_subtree:hover > ul {
        left: 15.5em;
    }
}

// Indent subtrees in navigation portlets

.portletNavigationTree .portletContent > ul {
        ul.navTreeLevel2 {
                padding-left: 0.5em;
            }
        ul ul.navTreeLevel3 {
                padding-left: 0.5em;
            }
}


/* Search box */
.searchSection {
    float: left;
}

/* Anonymous tools */
#personaltools-login {
    display: none;
}


/* Tables */

table {
    color: @oag-black;
}
/* invisible grid */
/* .invisible is deprecated as it conflicts with a Plone/Barceloneta style, */
/* use .invisible-grid instead. */
table.invisible, table.invisible-grid {
    > thead, > tbody, > tfoot {
        > tr {
            > th, > td {
                padding: @plone-table-cell-padding;
                line-height: @plone-line-height-base;
                vertical-align: top;
                border-top: 0 solid @plone-table-border-color;
                border-collapse: collapse;
                border-width: 0 0 1px 0;
            }
        }
    }
}

table.invisible, table.invisible-grid {
    border: 0;
    > thead {
        background-color: @plone-body-bg;
    }
    th, td {
        border: 0;
    }
}
.table-clean .odd td {
    background-color: #fff;
    border-left-color: #ddd;
}
.table-clean td {
    text-align: left;
}

table.padded {
    > thead, > tbody, > tfoot {
        > tr {
            > th, > td {
                padding: @plone-table-cell-padding;
                line-height: @plone-line-height-base;
                vertical-align: top;
            }
        }
    }
}
table.reversed-border {
    > thead, > tbody, > tfoot {
        > tr {
            > th, > td {
                border: 2px solid white;
            }
        }
    }
}

tr.audit-orange, td.audit-orange {
    background-color: #fce4d4 !important;
}
tr.audit-blue, td.audit-blue {
    background-color: #e2ecf6 !important;
}

tr.audit-green, td.audit-green {
    background-color: #c5f4d2 !important;
}

tr.audit-red, td.audit-red {
    background-color: red !important;
}

th.audit-coral-dark, tr.audit-coral-dark, td.audit-coral-dark {
    background-color: #e59890 !important;
    color: black;
}
tr.audit-coral, td.audit-coral {
    background-color: #efcbc8 !important;
}
tr.audit-coral-light, td.audit-coral-light {
    background-color: #f6e5e4 !important;
}
tr.audit-coral-lighter, td.audit-coral-lighter {
    background-color: #f9f1f0 !important;
}

td.vertical {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    vertical-align: middle !important;
    text-align: center;
}

tbody>tr.first-right>:first-child,
tbody>tr.second-right>:nth-child(2),
tbody>tr.third-right>:nth-child(3),
tbody>tr.fourth-right>:nth-child(4),
tbody>tr.fifth-right>:nth-child(5) {
    text-align: right;
}

/* rrssb styles */

.rrssb-buttons {
    width: 80%;
    margin: 0;
    display: none;

    li {
        max-width: 260px;
    }
}

/* Fix for old styles */

table.invisible {
    visibility: visible !important;
}

.big {
    font-family: @plone-font-family-alt;
    font-size: 1.66em !important;
    font-style: italic;
    color: @oag-teal;
    margin: 1em 0 1em 0;
}

/* Event Registration version */

article.event.summary {
    float: none;
    max-width: none;
    box-shadow: none;
    padding: inherit;
    margin-left: inherit;

    h2 {
        color: @oag-teal-dark;
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 0.75em;
        margin-top: 0.75em;
    }
    h2::after {
        position: absolute;
        content: "";
        height: 6px;
        width: 9%;
        left: 0;
        bottom: 0;
        background-color: @oag-teal;
    }

}

/* Past event view */
ul.event.summary.details.pastevent {
    list-style-type: none;
    background-color: @oag-gray-lightest;
    a {
        color: @plone-link-color-on-grey;
    }
}
