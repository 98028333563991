// Custom additions to print stylesheet

@media print {
    body {
        font-size: 14px;
    }
    #portal-footer-wrapper {
        display: block;
    }
    #siteaction-address, #siteaction-copyright {
        display: block;
        visibility: visible;
    }

  a[href]::after {
    content: " (" attr(href) ")";
  }

  a[href]::after {
      font-size: 12px;
    content: " (" attr(href) ")";
  }

  .oag-listing-thumb {
      float: none;
  }

  body.portaltype-home-with-portlets {
        a[href]::after {
            content: "";
        }
        #hero-column-social {
            a[href]::after {
                content: " (" attr(href) ")";
            }
        } 
    }

    #oag-documentActions {
        display: none;
    }
}