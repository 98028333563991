//*// MIXIN FONT FACE //*//
@theme-roboto-path: '/++theme++auditnz2022/barceloneta/less/';

.font-face(@font-path; @file-name; @font-family; @font-weight; @font-style; @svg-id; @local, ~''; ) {
  @font-face {
    font-family: @font-family;
    src: url('@{theme-roboto-path}@{font-path}@{file-name}.eot');
    src: @local url('@{theme-roboto-path}@{font-path}@{file-name}.eot?#iefix') format('embedded-opentype'),
    url('@{theme-roboto-path}@{font-path}@{file-name}.woff') format('woff'),
    url('@{theme-roboto-path}@{font-path}@{file-name}.ttf') format('truetype'),
    url('@{theme-roboto-path}@{font-path}@{file-name}.svg#@{svg-id}') format('svg');
    font-weight: @font-weight;
    font-style: @font-style;
  }
}
