// Setup

@hp-font-size-base: 18px;
@hp-font-size-headline: floor((@hp-font-size-base * 3.5)); // 63px italic
@hp-font-size-title: floor((@hp-font-size-base * 2)); // 36px bold
@hp-font-size-subtitle: floor((@hp-font-size-base * 1.78)); // 32px
@hp-font-size-intro: floor((@hp-font-size-base * 1.5)); // 27px
@hp-font-size-button: floor((@hp-font-size-base * 1.15)); // 20px bold
@hp-font-size-body: @hp-font-size-base; // 18px

@hp-font-size-headline-sm: floor((@hp-font-size-base * 2.15)); // 38px italic
@hp-font-size-subtitle-sm: floor((@hp-font-size-base * 1.36)); // 24px bold

@hp-color-headline: @plone-gray-light;
@hp-color-title: @oag-teal-dark;
@hp-color-subtitle: @oag-black;
@hp-color-intro: @oag-black;
@hp-color-button: @plone-body-bg;
@hp-color-body: @oag-black;

@hp-bg-color: #eef3f9;

// Image ratios are 1.5
@hp-3p-img-width: 300px; // hp3 300:200
@hp-2p-img-width: 410px; // hp2 410:273
@hp-2p-img-height: 273px;
@hp-vp-img-width: 360px; // hpv 360:240
@hp-lr-img-width: 700px; // hplr 700:468

// Full width layout


body.fullwidth_mosaic_layout {
.container {
    width: 100% !important;
}
.outer-wrapper > .container {
    // have to override media-queries in grid.plone.less
    // and plone-mosaic.css.
    width: 100% !important;
    .mosaic-grid-row {
        width: auto !important;
        margin-left: -15px !important;
        margin-right: -15px!important;
    }
}

#content {
    max-width: 100%;
    margin-bottom: 0;
}

.mosaic-width-full {
    padding-left: 0;
    padding-right: 0;
}

.plone-breadcrumb {
    margin-bottom: 0;
}
// No top or bottom padding here so consecutive banner images
// are continuous.
.mosaic-grid-row {
}

div.mosaic-grid-row:nth-of-type(odd) {
    background-color: @hp-bg-color;
}

#portal-footer-wrapper {
    padding-top: 0;
        padding-bottom: 0;
}

.mosaic-grid-cell {
    a {
    border-bottom: 1px solid @plone-gray-lighter;
    }
    a:hover {
        text-decoration: none;
        border-bottom: 1px solid @plone-link-color;
    }
}
// Read more button links
a.readmore {
    border-bottom: none;
}
}


// Homepage banner tiles

.mosaic-oaghpbanner-tile {
    // stops multiple banners overlapping on page
    position: relative;
}

.hpbanner {
    background-size: contain;
    width: 100%;
    aspect-ratio: 3.53;
    background-repeat: no-repeat ;
    background-image: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0, 0.5) 50%, rgba(0,0,0,0) 75%), url('++theme++auditnz2022/static/img/banner-default.png');
    margin-bottom: -1.5em;
}
.hpbanner-text {
    position: absolute;
    font-family: @plone-font-family-alt;
    top: 0;
    padding-top: 3%;
    max-width: 50vw;
}
.hpbanner-h1 {
    margin-left: 3%;
    padding-bottom: 0.5em;
    font-size: @hp-font-size-headline;
    color: white;
    line-height: 1.2;
    a {
        color: #ade5ff;
    }
}
.hpbanner-p {
    margin-left: 3%;
    font-size: @hp-font-size-intro;
    color: white;
    line-height: 1.4;
    a {
        color: #ade5ff;
    }
}

@media (max-width: 1500px) {
    .hpbanner-h1 {
        font-size: floor((@hp-font-size-headline * 0.9));
    }

    .hpbanner-p {
        font-size: floor((@hp-font-size-intro * 0.9));
    }
}

@media (max-width: 1325px) {
    .hpbanner-h1 {
        font-size: floor((@hp-font-size-headline * 0.8));
    }

    .hpbanner-p {
        font-size: floor((@hp-font-size-intro * 0.8));
    }
}
@media (max-width: @plone-screen-md-max) {
    .hpbanner {
        background-image: url('++theme++auditnz2022/static/img/banner-default.png');;
    }
    .hpbanner-text {
        position: static;
        padding-top: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        max-width: none;
        font-size: @hp-font-size-intro;
    }
    .hpbanner-h1 {
        font-size: @hp-font-size-headline;
        padding-top: 1em;
    }
    .hpbanner-h1, .hpbanner-p {
       color: @hp-color-body;
        margin-left: 0;
        text-align: center;
        a {
            color: @plone-link-color;
        }
    }
}
@media (max-width: @plone-screen-xs-max) {
    .hpbanner {
        aspect-ratio: 2.18;
        height: calc(100vw * 0.4568); // 1/aspect ratio of sml version 2.18
    }
    .hpbanner-h1 {
        font-size: @hp-font-size-headline-sm;
    }
}

// Video tile


@homepage-video-width: 512px;
@homepage-video-height: 288px;

.mosaic-oaghpvideo-tile>.mosaic-tile-content {
    padding-top: (((@homepage-video-height / 0.7)-@homepage-video-height)/2);
    padding-bottom: (((@homepage-video-height / 0.7)-@homepage-video-height)/2);
}
.hpvideo {
    font-family: @plone-font-family-alt;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}
.hpvideo-v {
    width: @homepage-video-width;
    height: @homepage-video-height;
}
.hpvideo-p {
    margin-top: 1rem;
    font-size: @hp-font-size-body;
    color: @hp-color-body;
    width: @homepage-video-width;
    max-width: 100%;
}
@media (max-width: @plone-screen-xs-max) {
    @homepage-video-width: 256px;
    @homepage-video-height: 144px;

    .mosaic-oaghpvideo-tile>.mosaic-tile-content {
        padding-top: (((@homepage-video-height / 0.7)-@homepage-video-height)/2);
        padding-bottom: (((@homepage-video-height / 0.7)-@homepage-video-height)/2);
    }

    .hpvideo-v {
        width: @homepage-video-width;
        height: @homepage-video-height;
    }

    .hpvideo-p {
        width: @homepage-video-width;
    }
}
@media (min-width: @plone-screen-md-min) {
    @homepage-video-width: 970px;
    @homepage-video-height: 546px;

    .mosaic-oaghpvideo-tile>.mosaic-tile-content {
        padding-top: (((@homepage-video-height / 0.7)-@homepage-video-height)/2);
        padding-bottom: (((@homepage-video-height / 0.7)-@homepage-video-height)/2);
    }

    .hpvideo-v {
        width: @homepage-video-width;
        height: @homepage-video-height;
    }

    .hpvideo-p {
        width: @homepage-video-width;
    }
}


// Three portlets tile

.hp3portlets-container {
    padding-top: 1.5em;
    font-family: @plone-font-family-alt;
}
.hp3portlets {
    display: flex;
    // space-around for IE11
    justify-content: space-around;
    padding-bottom: 1.5em;
}
.hp3portlets-h2 {
    font-size: @hp-font-size-title;
    color: @hp-color-title;
    font-weight: @plone-font-weight-bold;
    text-align: center;
    margin-bottom: @plone-line-height-computed * 2;
}
.hp3portlets-h3 {
    width: @hp-3p-img-width;
    p {
        font-size: @hp-font-size-subtitle;
        font-weight: @plone-font-weight-bold;
        color: @hp-color-intro;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}
.hp3portlets-p {
    width: @hp-3p-img-width;
    p {
        font-size: @hp-font-size-body;
        color: @hp-color-body;
    }
}
.hp3portlets-tweet {
    p {
        margin-left: 10px;
        margin-top: 0;
    }
}
@media (max-width: @plone-screen-sm-max) {
    .hp3portlets {
        flex-direction: column;
        align-items: center;
    }
    .hp3portlet  {
        margin-bottom: 2.5em;
    }
}


// Two portlets tile


.hp2portlets {
    font-family: @plone-font-family-alt;
    display: flex;
    // space-around for IE11
    justify-content: space-around;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}
.hp2portlets-h3 {
    width: @hp-2p-img-width;
    p {
        font-size: @hp-font-size-subtitle;
        font-weight: @plone-font-weight-bold;
        color: @hp-color-intro;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}
.hp2portlets-p {
    width: @hp-2p-img-width;
    p {
        font-size: @hp-font-size-body;
        color: @hp-color-body;
    }
}
.hp2portlets-v {
    width: @hp-2p-img-width;
    height: @hp-2p-img-height;
}
@media (max-width: @plone-screen-sm-max) {
    .hp2portlets {
        flex-direction: column;
        align-items: center;
    }
    .hp2portlet  {
        margin-bottom: 2.5em;
    }
}
@media (max-width: @plone-screen-xs-max) {
    .hp2portlet{
        width: 256px;
    }
    .hp2portlets-v {
        width: 256px;
        height: 144px;
    }
    .hp2portlets-p, .hp2portlets-h3 {
        width: 256px;
    }
    .hp2portlet img {
        width: 256px;
    }
}


// Six portlets tile

.hp6portlets {
    font-family: @plone-font-family-alt;
    padding-bottom: 1.5em;
}

.hp6portlets-row {
    display: flex;
    justify-content: space-around;
}
.hp6portlet {
    border-top: 1px solid @plone-gray-lighter;
    padding-top: 1.5em;
    padding-bottom: 2em;
}
.hp6portlets-h2 {
    font-size: @hp-font-size-title;
    color: @hp-color-title;
    font-weight: @plone-font-weight-bold;
    text-align: center;
    margin-bottom: @plone-line-height-computed * 2;
    padding-top: 1.5em;
}
.hp6portlets-h3 {
    width:550px;
    p {
        font-size: @hp-font-size-subtitle;
        color: @hp-color-intro;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}
.hp6warning {
    width: 550px;
}
.hp6portlets-p {
    width: 550px;
    p {
        font-size: @hp-font-size-body;
        color: @hp-color-body;
    }
}
@media (max-width:@plone-screen-md-max) {
    .hp6portlets-h3, .hp6portlets-p, .hp6warning {
        width: 320px;
    }
}
@media (max-width: @plone-screen-sm-max) {
    .hp6portlets {
        flex-direction: column;
        align-items: center;
    }
    .hp6portlets-h3, .hp6portlets-p, .hp6warning {
        width: 300px;
    }
}
@media (max-width: 640px) {
    .hp6portlets-row {
        flex-direction: column;
        align-items:center;
    }
}


// Three Vertical portlets tile


.hp3verticals {
    font-family: @plone-font-family-alt;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}
.hp3vertical{
    display: flex;
    width: 900px;
    margin-bottom: 1.5em;
}
.hp3verticals-right {
    margin-left: 2.5em;
}
.hp3verticals-h2 {
    font-size: @hp-font-size-title;
    color: @hp-color-title;
    font-weight: @plone-font-weight-bold;
    text-align: center;
    margin-bottom: @plone-line-height-computed * 2;
}
.hp3verticals-h3 {
    p {
        font-size: @hp-font-size-subtitle;
        font-weight: @plone-font-weight-bold;
        color: @hp-color-intro;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}
.hp3verticals-p {
    p {
        font-size: @hp-font-size-body;
        color: @hp-color-body;
    }
}
@media (max-width: @plone-screen-sm-max) {
    .hp3vertical {
        width: 700px;
    }
}
@media (max-width: @plone-screen-xs-max) {
    .hp3verticals-right {
        margin-left: 0;
    }
    .hp3vertical {
        flex-direction: column;
        width: @hp-vp-img-width;
        padding-left: 6.25%;
        padding-right: 6.25%;
    }
}
@media (max-width: 340px) {
    .hp3vertical {
        padding-left:0;
        padding-right:0;
    }
}

// Feedback tile


.mosaic-oaghpfeedback-tile {
    background-color: @plone-body-bg;
    padding-bottom: 3em;
    margin-bottom: -1.5em;
}
.hpfeedback {
    width: 100%;
    background-color: #ede4b1; // TODO: use variable
    margin-left: auto;
    margin-right: auto;
}
.hpfeedback-b {
    margin-left: auto;
    margin-right: auto;
    padding-top: 3em;
    padding-bottom:3em;
    margin-top: -1.5em;
    margin-bottom: -1.5em;
    display: block;
    width: 229px;
}
.hppagetop-b {
    float:right;
    margin-top: -50px;
    margin-right: 15px;
}
@media (max-width: @plone-screen-sm-max) {
    .hppagetop-b {
        margin-top: -23px;
        img {
            width: 42px;
            height: 42px;
        }
    }
}
@media (max-width: @plone-screen-xs-max) {
    .hppagetop-b {
        margin-top: -10px;
        img {
        width: 29px;
        height: 29px;
        }
    }
}


// Social media tile


#hpsocial {
    list-style-type: none;
    display: flex;
    // space-around for IE11
    justify-content: space-around;
    margin-bottom: 0;
    padding-left: 0;
    li a {
        font-size: @hp-font-size-headline;
    }
}
.hpsocial {
    margin-top: 2em;
}
#hpsocial-twitter,
#hpsocial-facebook,
#hpsocial-linkedin,
#hpsocial-instagram,
#hpsocial-sharing,
#hpsocial-feedback {
    a {
        color: #7099bf;
        border-bottom-width: 0;
    }
}
@media (max-width: @plone-screen-sm-max) {
    #hpsocial li a {
        font-size: @hp-font-size-headline-sm;
    }
}
@media (max-width: @plone-screen-xs-max) {
    #hpsocial li a {
        font-size: @hp-font-size-subtitle-sm;
    }
}

// Image left and image right tiles

.lilportlets, .rilportlets {
    font-family: @plone-font-family-alt;
    display: flex;
    justify-content: center;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    padding-left: 1em;
    padding-right: 1em;
    font-size: @hp-font-size-body;
}
.lilportlets-h2, .rilportlets-h2 {
    p {
        font-size: @hp-font-size-subtitle;
        font-weight: @plone-font-weight-bold;
        color: @hp-color-intro;
        margin-bottom: 1rem;
    }
}
.lilportlets-p, .rilportlets-p {
    p {
        font-size: @hp-font-size-body;
        color: @hp-color-body;
    }
}

.lilportlets-h2, .lilportlets-p {
    margin-left: 2em;
}

.lilportlet, .rilportlet {
    width: 50%;
    max-width: @hp-lr-img-width;
    img {
        aspect-ratio: 1.5;
}
}
  .rilportlet img {
      padding-left: 2em;
  }



@media (max-width: @plone-screen-sm-max) {
    .lilportlets, .rilportlets {
        flex-wrap: wrap;
    }
    .lilportlet, .rilportlet {
        width: 100%;
    }
    .lilportlet img {
        margin-bottom: 1em;
    }
}
@media (max-width: @plone-screen-xs-max) {
}
// Annual Report Nav tile

// TODO: Use these for generalised nav tile
// #annualreportnav2022 {
//     list-style-type: none;
//     display: flex;
//     // space-around for IE11
//     justify-content: space-around;
//     margin-top: -6px;
//     margin-bottom: 0;
//     padding-left: 0;
//     background-color: #627774;
//     li a {
//         color: white;
//     }
// }

// Values tile
.valuestile-h2 {
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
    font-size: @hp-font-size-title;
    color: @hp-color-title;
    font-weight: @plone-font-weight-bold;
    text-align: center;
    margin-bottom: @plone-line-height-computed * 2;
    padding-top: 1.5em;
}

.macaw-tabs.macaw-elegant-tabs {
    max-width: 80vw;
    border-bottom: 2px solid @oag-teal;
    padding-bottom: 0;
    margin-bottom: 1.5em;
    .valuestile-h3 {
        font-size: @hp-font-size-subtitle;
        font-weight: @plone-font-weight-bold;
        margin-left: 3%;
        padding-top: 16px;
        max-width: 66vw;
    }
    .valuestile-p {
        font-size: @hp-font-size-body;
        margin-left: 3%;
        max-width: 66vw;
        padding-bottom: 16px;
    }
}

.macaw-tabs.macaw-elegant-tabs > [role=tabpanel] > * {
    margin-top: 12px;
    margin-bottom: 12px;
}

.macaw-tabs.macaw-elegant-tabs > [role=tabpanel]:focus {
    margin-bottom: -6px;
    border-bottom: 8px solid @oag-teal;
}

.macaw-tabs.macaw-elegant-tabs {
    border-right: none;
}

.macaw-tabs.macaw-elegant-tabs > [role=tablist] > * {
    border-right: none;
    border-radius: 0;
}
.macaw-tabs.macaw-elegant-tabs > [role=tablist] > [role=tab] {
    border-bottom: 2px solid @oag-teal;
    padding-bottom: 1.875rem;
}
.macaw-tabs.macaw-elegant-tabs > [role=tablist] > [role=tab][area-selected=true] {
    border-bottom: 8px solid @oag-teal;
}

@media only screen and (min-width: 1260px) {
    .macaw-tabs.macaw-elegant-tabs {
        max-width: 80vw;
    }
}

@media only screen and (max-width: 575px) {
    .macaw-tabs.macaw-elegant-tabs > [role=tabpanel] {
        margin-bottom: 8px;
    }
    .macaw-tabs.macaw-elegant-tabs > [role=tabpanel]:focus {
        margin-bottom: 0;
    }
    .macaw-tabs.macaw-elegant-tabs.vertical > [role=tablist] > [role=tab] .label {
        display: block;
    }
    .macaw-tabs.macaw-elegant-tabs > [role=tablist] > [role=tab] {
        border-bottom: none;
    }
}
